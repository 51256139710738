import React from "react";
import { Link } from "gatsby";
import HowToLayout from "../../../components/how-to-layout";

const Standings = () => (
  <HowToLayout>
    <h2>Standings</h2>
    <section className="documentation-section">
      <p>
        The Standings tab is updated in real time just like every other part of
        the app. This means that when teams submit scores, the standings will be
        updated immediately without a page refresh.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="standings-sorting">Sorting the Standings</h3>
      <p>
        The standings are by default sorted by team Winning Percentage. This is
        the recommended 4BAGR sorting, however we do offer other ways to sort
        standings if you wish:
      </p>
      <ul>
        <li>
          Win %: Most common - Sorts standings by Win % and Points For is the
          tiebreaker
        </li>
        <li>
          Win/Loss: Will sort the standings by wins, losses, then points for. Be
          careful, as the team with the most wins is not always the team with
          the best Win %.
        </li>
        <li>Points For: Sorts by total points scored from all games</li>
      </ul>
      <p>
        Update the sorting of the standings at any time from the 'League
        Settings' Screen.
      </p>
    </section>
    <section className="documentation-section">
      <h3 id="edit-teams">Edit Teams</h3>
      <p>
        The standings screen is also where you can edit team names and set
        handicaps. Click on a team within the standings to update either.
      </p>
    </section>
  </HowToLayout>
);

export default Standings;
